@import 'node_modules/lightpick/css/lightpick.css';

.lightpick {
  font-family: var(--fm-typo-text-font-family);

  &__months {
    display: flex;
    flex-wrap: wrap;
  }
}

.lightpick__days,
.lightpick__days-of-the-week {
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex-basis: calc(1 / 7 * 100%);
  }
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped {
  background-color: var(--fm-color-surface-accent-default);
}

.lightpick__day.is-end-date.is-in-range,
.lightpick__day.is-start-date.is-in-range.is-flipped {
  background-color: var(--fm-color-surface-accent-default);
}

.lightpick__apply-action {
  background-color: var(--fm-color-surface-accent-default);
}

.lightpick__day.is-disabled.is-forward-selected:not(.is-start-date) {
  background-image: none;
  background-color: rgba(153, 75, 168, 0.1);
}

.lightpick__day.is-in-range {
  border-radius: 0;
  background-image: none;
  background-color: rgba(153, 75, 168, 0.1);
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23994bd8' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-in-range:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(153,75,168, 0.5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}
