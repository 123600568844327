@import 'legacy/colors';

.badge {
  display: inline-block;
  margin: 0 4px 4px 0;
  border-radius: 10px;
  background: $color-bright-sun;
  padding: 1px 5px;
  color: $color-cod-gray;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.2;

  &--inverse {
    color: $color-white;
  }

  &--icon {
    i {
      vertical-align: middle;
    }
  }

  &--big {
    vertical-align: middle;
    border-radius: 2px;
    padding: 2px 5px;
    font-size: 13px;
  }

  &--status {
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 3px;
    padding: 3px 6px;
    font-size: 13px;
  }

  &--block {
    margin: 3px 0 0 0;
  }

  &--block-r {
    margin: 3px 0 0 3px;
  }

  &--red {
    background-color: $color-punch;
    color: $color-white;
  }

  &--blue {
    background-color: $color-cerulean;
    color: $color-white;
  }

  &--blue-text {
    cursor: pointer;
    border: 1px solid $color-cerulean;
    background-color: $color-white;
    color: $color-cerulean;
  }

  &--green {
    background-color: $color-mountain-meadow;
    color: $color-white;
  }

  &--purple {
    background-color: $color-wisteria;
    color: $color-white;
  }

  &--draft,
  &--sealed {
    background-color: $color-bright-sun;
    color: $color-cod-gray;
  }

  &--pending,
  &--nobid {
    background-color: $color-orange-peel;
    color: $color-white;
  }

  &--opened {
    background-color: $color-mountain-meadow;
    color: $color-white;
  }

  &--closed {
    background-color: $color-cerulean;
    color: $color-white;
  }

  &--canceled {
    background-color: $color-dark-alto;
    color: $color-cod-gray;
  }

  &--awarded {
    background-color: $color-wisteria;
    color: $color-white;
  }

  &--pin {
    background-color: $color-punch;
    color: $color-white;
  }

  &--dark {
    background-color: #293141;
    color: $color-white;
  }

  &--inactive {
    border: 1px solid $color-alizarin-crimzon;
    background: $color-white;
    color: $color-alizarin-crimzon;
  }

  &--outline {
    border: 1px solid $color-mercury;
    background: $color-white;
    color: $color-emperor;
  }

  &--show-more {
    border: 1px solid $color-cerulean;
    background: transparent;
    color: $color-cerulean;
  }

  &--primary {
    border: 1px solid $color-white;
    background-color: $color-white;
    color: darken($color-cerulean, 7%);
  }

  &--default {
    border: 1px solid $color-white;
    background-color: $color-white;
    color: $color-mine-shaft;
  }

  &--rect {
    border-radius: 3px;
    padding-right: 3px;
    padding-left: 3px;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--link {
    cursor: pointer;

    &:hover {
      border-color: $color-cerulean;
      background-color: $color-cerulean;
      color: $color-white;
    }
  }

  &--multi {
    margin: 0 4px 4px 0;
  }
}
