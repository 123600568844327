@import 'colors';

@mixin tree-theme($theme, $typography) {
  .mat-tree {
    border: 1px solid $str-primary;

    > .top-level:not(:first-child) > .mat-tree-node,
    > .top-level.mat-tree-node:not(:first-child) {
      border-top: 1px solid $str-primary;
    }

    .mat-tree-node .mat-checkbox-layout {
      white-space: break-spaces;

      .mat-checkbox-inner-container {
        align-self: center;
        margin: inherit;
        margin-right: 8px;
      }
    }

    .mat-icon-button .mat-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-arrow {
        font-size: 22px !important;
      }
    }
  }
}
