// Background
$bg-primary: #ffffff;
$bg-secondary: #f8f8f9;
$bg-secondary-2: #e3e5e7;
$bg-tertiary: #f6f5f3;
$bg-hover: #e3f0fb;
$bg-hover-2: #cae7ff;
$bg-highlight: #fff8e4;
$bg-highlight-2: #fff8e4;
$bg-success: #eafaef;
$bg-success-2: #dcedde;
$bg-awarded: #dfcee8;
$bg-awarded-2: #d5b5e1;
$bg-revert: #212121;
$bg-error: #fef2f1;
$bg-error-2: #fde1df;
$bg-lightblue: #f1f9ff;

// Fonts & Icons
$fn-ic-primary: var(--fm-color-text-primary);
$fn-ic-secondary: #747474;
$fn-ic-tertiary: #949494;
$fn-ic-quaternary: #c5c5c7;
$fn-ic-fivefold: #dfdfdf;
$fn-ic-active: var(--fm-color-text-accent-default);
$fn-ic-hover: var(--fm-color-text-accent-hover);
$fn-ic-pressed: var(--fm-color-text-accent-pressed);
$fn-ic-reverse: #ffffff;
$fn-ic-error: #f44336;
$fn-ic-highlight: #f0ce60;
$fn-ic-highlight-2: #ecb80f;
$fn-ic-success: #52a55c;
$fn-ic-awarded: #9667b8;

//Interface
$int-primary: #2a3445;
$int-secondary: var(--fm-primary-600);
$int-brand: #332994;
$int-error: #f44336;
$int-success: #52a55c;
$int-highlight: #f0ce60;

// Status
$st-all: #4a5056;
$st-draft: #f0ce60;
$st-highlight: #f0ce60;
$st-pending: #f99122;
$st-opened: #52a55c;
$st-success: #52a55c;
$st-closed: #4fa7ee;
$st-bid: #4fa7ee;
$st-active: #4fa7ee;
$st-awarded: #9667b8;
$st-tertiary: #c5c5c7;
$st-cancelled: #c5c5c7;
$st-archieved: #c84930;
$st-unreg: #f0ce60;

//Stroke
$str-primary: #dfdfdf;
$str-secondary: #c5c5c7;
$str-tertiary: #949494;
$str-active: var(--fm-primary-600);
$str-highlight: #f0ce60;
$str-success: #52a55c;

// Transparent
$tr-primary: #212121;
$tr-active: #994bd8;
$tr-reverse: #ffffff;

// Controls
$ctr-success: #52a55c;
$ctr-primary: var(--fm-primary-600);
