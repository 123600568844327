@mixin correct-hyphenation {
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}

@mixin title-font {
  font-weight: var(--titleFontWeight);
  font-size: var(--titleFontSize);
}
