@import 'legacy/colors';

$yellow: #f8cd47;
$red: #db3b20;
$blue: #01a9f4;
$green: #1ca955;
$grey-light: #dbdbdb;
$black: #000000;

.notify {
  display: block;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0 4px 20px 0 rgba($black, 0.19);
  border-radius: 4px;
  background: $color-white;
  padding: 10px 9px;
  width: 380px;

  &__time {
    position: absolute;
    top: 13px;
    right: 10px;
    color: rgba($color-cod-gray, 0.35);
    font-size: 10px;

    &--top {
      display: block;
      position: unset;
      margin-bottom: 5px;
      text-align: right;
    }
  }

  &__icon {
    color: $black;
    font-size: 24px;
    text-align: right;
  }

  &__delimiter {
    position: absolute;
    right: 0;
    left: 4px;
    margin-top: 12px;
    background: $grey-light;
    width: 100%;
    height: 1px;
  }

  &__button {
    margin: 16px 0 -4px 0;
    padding: 8px 0;
    color: rgba($color-cod-gray, 0.54);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0;
    text-align: center;

    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }

  &__title {
    padding-right: 8px;
    color: rgba($color-cod-gray, 0.87);
    font-weight: 500;
    font-size: 14px;
  }

  &__message {
    margin-top: 4px;
    padding-right: 8px;
    color: rgba($color-cod-gray, 0.55);
    font-size: 14px;
    line-height: 17px;
    white-space: pre-line;
    word-break: break-word;
  }

  &__wrapper {
    position: relative;
  }

  &--warning {
    border-left: 4px solid $yellow;

    .notify__icon {
      color: $yellow;
    }
  }

  &--error {
    border-left: 4px solid $red;

    .notify__icon {
      color: $red;
    }
  }

  &--loader,
  &--info {
    border-left: 4px solid $blue;

    .notify__icon {
      color: $blue;
    }
  }

  &--loader {
    .notify__icon {
      i {
        animation: rotate-animation 2s infinite linear;
      }
    }
  }

  &--success {
    border-left: 4px solid $green;

    .notify__icon {
      color: $green;
    }
  }

  &--top-right {
    position: fixed;
    top: 76px;
    right: 16px;
    z-index: 9999;
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
