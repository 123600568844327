@import 'legacy/colors';

$item-line-height: 24px;
$section-padding: 15px;
$bottom-section-height: 100px;

.sidebar {
  position: relative;
  background: var(--fm-color-background-secondary);
  width: 200px;
  height: 100%;
  color: var(--fm-color-text-secondary-default);

  &__counter {
    align-self: center;
    margin-right: 2px;
    border-radius: 20px;
    background: $color-cerulean;
    padding: 0 4px;
    min-width: 17px;
    height: 17px;
    color: #fff;
    font-size: 10px;
    line-height: 17px;
    text-align: center;

    &:empty {
      display: none;
    }
  }

  &__content {
    padding-top: 16px;
    width: 100%;

    &--top {
      height: calc(100% - #{$bottom-section-height});
      overflow-x: hidden;
      overflow-y: auto;
    }

    &--bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: $bottom-section-height;
    }
  }
}

.sidebar-section {
  border-top: 1px solid var(--fm-color-border-subtle);
  padding: 15px 4px;

  &:first-child {
    border: none;
  }

  &--first {
    border: none;
    padding-bottom: 0;
  }

  &__title {
    padding: 5px 6px;
    height: 26px;
    overflow: hidden;
    color: var(--fm-color-text-secondary-default);
    font-size: 13px;
    user-select: none;
  }

  &__icon {
    font-size: 11px;
  }

  &__item {
    display: flex;
    position: relative;
    gap: 4px;
    cursor: pointer;
    margin: 2px 0;
    outline: none;
    border: none;
    border-radius: 8px;
    background: transparent;
    padding: 5px;
    width: 100%;
    overflow: hidden;
    color: var(--fm-color-text-secondary-default);
    font-size: 14px;
    user-select: none;
    text-decoration: none;

    &:hover {
      background: var(--fm-color-surface-tertiary-subtle-hover, #f5f2f7);
      color: var(--fm-color-text-secondary-hover);
    }

    &--active {
      background: var(--fm-color-surface-tertiary-subtle-pressed, #eeeaf0);
      color: var(--fm-color-text-secondary-pressed);

      &:hover {
        background: var(--fm-color-surface-tertiary-subtle-pressed, #eeeaf0);
        color: var(--fm-color-text-secondary-pressed);
      }
    }
  }

  &__item-icon {
    flex-shrink: 0;
    vertical-align: top;
    width: 20px;
    height: 24px;
    color: var(--fm-color-text-secondary-default);
    font-size: 14px;
    line-height: $item-line-height;
    text-align: center;
  }

  &__item-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: $item-line-height;
  }

  &__item-badge {
    font-size: 10px;
  }
}

.sidebar.sidebar--folded {
  width: 45px;

  .sidebar-section__item {
    justify-content: center;
  }

  .sidebar-section {
    &__item-label {
      display: none;
    }
  }

  .sidebar-collapse {
    &__label {
      display: none;
      font-size: 13px;
    }
  }
}

.sidebar-divider {
  margin: 0 $section-padding;
  background: var(--fm-color-border-subtle);
  height: 1px;
}

.sidebar-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 45px;

  &__icon {
    color: var(--fm-color-text-secondary-default);
  }

  &__label {
    display: inline-block;
    transition: opacity 0.3s ease-in-out;
    margin: 0 0 0 10px;
    overflow: hidden;
    color: var(--fm-color-text-secondary-default);
    font-size: 13px;
    text-overflow: clip;
    white-space: nowrap;
  }
}
