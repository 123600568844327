$reflex-grid-spacing: 8px;
$reflex-columns: 24;
$legacy-support: true;

@import 'reflex-grid/scss/reflex';

.grid {
  margin: -$reflex-grid-spacing;

  &-bleed-y {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-bleed-x {
    margin-right: 0;
    margin-left: 0;
  }
}
