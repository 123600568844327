@import 'legacy/colors';

.no-events {
  pointer-events: none;
}

.auto-events {
  pointer-events: auto;
}

.break-line {
  word-wrap: break-word;
  white-space: pre-line;
  word-break: break-word;
}

.blurred {
  pointer-events: none;
  color: transparent !important;
  user-select: none;
  text-shadow: 0 0 17px $color-cod-gray;
}

.cursor {
  &--pointer {
    cursor: pointer;
  }
}

.text-align {
  &--right {
    text-align: right;
  }
}

.rotated {
  transition: transform 0.2s linear;

  &--180 {
    transform: rotate(180deg);
  }
}

.ellipsis-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  color: $color-light-black !important;
}
