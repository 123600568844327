@import 'legacy/colors';

@mixin form-field-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  .mat-form-field {
    width: 100%;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix  {
    display: flex;
    align-items: center;

    .mat-icon-button {
      width: 26px;
      height: 26px;
    }
  }

  .mat-placeholder-required,
  .mat-focused .mat-form-field-required-marker {
    color: $color-alizarin-crimzon;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: map-get($primary, 600);
  }

  .mat-form-field-disabled {
    .mat-select-disabled .mat-select-trigger,
    .mat-form-field-flex {
      cursor: not-allowed;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-underline,
    .mat-form-field-underline {
      box-sizing: border-box;
      background-image: none;
      border-bottom: 1px dashed var(--fm-color-border-strong-default);
    }

    .mat-select.mat-select-disabled .mat-select-arrow,
    .mat-mdc-icon-button:disabled,
    .mat-form-field-suffix {
      color: var(--fm-color-text-secondary-disabled);
    }

    .mat-date-range-input-inner:disabled,
    .mat-input-element:disabled {
      color: inherit;
    }

    .mat-select-disabled .mat-select-value {
      color: inherit;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 6px 0 1em !important;
  }

  .mat-form-field .mat-chip-input {
    margin: 8px 0 0 0;
    width: 100%;
  }

  textarea.mat-input-element {
    box-sizing: content-box;
  }

  .mat-form-field.character-counter {
    :is(mat-hint, mat-error):last-of-type {
      flex: 1 0 auto;
    }

    .mat-form-field-hint-spacer:not(:only-child):last-child {
      display: none;
    }

    :is(mat-hint, mat-error):last-of-type::before {
      float: right;
      content: var(--characterCounter-summary);
    }

    .mat-form-field-hint-spacer:only-child::after {
      float: right;
      content: var(--characterCounter-summary);
      color: $fm-mat-hint-color;
    }
  }
}
