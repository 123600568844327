@use 'styles/normalize';

@use 'styles/theme/theme';
@use 'styles/fonts/fonts';
@use 'styles/third-party/third-party';
@use 'styles/legacy/legacy';

@use 'node_modules/@fairmarkit/ui/styles';
@use 'node_modules/@fairmarkit/ui/styles/breakpoints' as *;

@use 'quill-mention/dist/quill.mention.min.css';

:root {
  @each $name, $value in $grid-breakpoints {
    --fm-breakpoint-#{$name}: #{$value};
  }
}
