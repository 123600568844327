@import 'legacy/colors';

.page,
.page-wrapper {
  .title {
    margin: 0 0 8px 0;
    font: var(--fm-typo-display-sm-medium);
    letter-spacing: var(--fm-typo-display-sm-letter-spacing);

    &--sm {
      font: var(--fm-typo-display-xs-medium);
      letter-spacing: var(--fm-typo-display-xs-letter-spacing);
    }
  }

  .table {
    position: relative;
    border: 1px solid $fm-color-table-border;
    overflow-x: auto;

    &--no-border {
      border: none;
    }

    &__content {
      position: relative;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }

    &__thead {
      background: $color-white;
    }

    &__header {
      vertical-align: top;
      margin: 0;
      padding: 12px 12px;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 500;
      font-size: 12px;
      text-align: left;
      white-space: nowrap;

      &--border {
        border-top: 1px solid $fm-color-table-border;
      }

      &--sorting {
        cursor: pointer;
        user-select: none;

        &:hover {
          color: $color-cod-gray;
        }
      }

      &--full {
        width: 100%;
        min-width: 300px;
      }

      &--collapsed {
        width: 1%;
        white-space: nowrap;
      }

      &--number {
        text-align: right;
      }

      &--checkbox {
        padding-top: 13px;
        padding-bottom: 13px;
        width: 1%;
        white-space: nowrap;
      }
    }

    &__arrow--no-sorting {
      visibility: hidden;
    }

    &__arrow {
      position: absolute;
      transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      margin: 2px 0 0 4px;
      color: rgba(0, 0, 0, 0.5);

      &--desc {
        transform: rotate(180deg);
      }
    }

    &__row {
      &--red {
        background-color: rgba(255, 103, 103, 0.09);
      }

      &--urgent {
        background-color: rgba(241, 207, 96, 0.15);
      }

      &--light-blue {
        background-color: rgba($color-cerulean, 0.08);
      }
    }

    &__cell {
      vertical-align: top;
      border-top: 1px solid $fm-color-table-border;
      padding: 14px 12px;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.3;

      &--noborder {
        border-top: none;
      }

      &--no {
        padding: 13px 0 0 7px;
      }

      &--dashed {
        border-top-style: dashed;
      }

      &--bold {
        font-weight: 500;
      }

      &--full {
        width: 100%;
        min-width: 250px;
      }

      &--collapsed {
        width: 1%;
        white-space: nowrap;
      }

      &--checkbox {
        padding-top: 13px;
        padding-bottom: 13px;
        width: 1%;
        white-space: nowrap;
      }

      &--nowrap {
        white-space: nowrap;
      }

      &--number {
        text-align: right;
        white-space: nowrap;
      }

      &--date {
        padding-top: 17px;
        font-size: 12px;
      }

      &--small {
        padding-top: 17px;
        font-size: 12px;
      }

      &--icons {
        padding-top: 14px;
        padding-bottom: 14px;
        width: 0; // to prevent icons column stretch
        text-align: right;
        white-space: nowrap;
      }

      &--center {
        text-align: center;
      }

      &--empty {
        color: $color-silver;
        text-align: center;
      }

      &--urgency-title {
        font-weight: 700;
      }
    }

    &__icon {
      display: inline-block;
      cursor: pointer;
      margin-left: 16px;
      color: $color-mine-shaft;
      font-size: 16px;
      text-decoration: none;

      &:hover {
        color: $color-cerulean;
      }
    }

    &__link {
      transition: color 0.2s ease;
      color: darken($color-cerulean, 10%);
      text-decoration: none;

      &:hover {
        color: $color-cerulean;
      }
    }

    &__pointer {
      transition: color 0.2s ease;
      cursor: pointer;
      outline: none;
      color: $color-mine-shaft;
      text-decoration: none;

      &:hover {
        color: $color-cerulean;
      }
    }

    &__small {
      margin: 2px 0 0 0;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 400;
      font-size: 12px;
    }
  }

  .table--hover tr:hover {
    background: rgba($color-moss-green, 0.2);
  }

  .table--bordered {
    .table__header,
    .table__cell {
      border: 1px solid $fm-color-table-border;
    }

    .table__content tr:first-child {
      .table__header,
      .table__cell {
        border-top: none;
      }
    }

    .table__content tr:last-child {
      .table__header,
      .table__cell {
        border-bottom: none;
      }
    }

    .table__content tr th:first-child,
    .table__content tr td:first-child {
      border-left: none;
    }

    .table__content tr th:last-child,
    .table__content tr td:last-child {
      border-right: none;
    }
  }
}
