@import 'legacy/colors';

.superadmin-modal-title {
  margin-bottom: 20px;
  color: $color-black;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;

  &--first {
    margin-bottom: 7px;
  }

  &--small {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
  }
}

.settings-popup {
  .mat-tab-body-content {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.bid-subtitle {
  padding-bottom: 16px;
  color: $color-black;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
}
