@import 'legacy/colors';

mat-icon[ngxmatselectsearchclear] .fal {
  vertical-align: top !important;
}

.mat-menu-panel {
  min-height: auto !important;
  max-height: 70vh !important;
}

.mat-paginator-page-size-select {
  width: 56px;
}

.mat-icon-button {
  vertical-align: 10% !important;
}

.mat-button-wrapper {
  .fal {
    vertical-align: -5% !important;
    margin: 0 2px;
    font-size: 15px !important;
  }
}

.mat-stroked-button.mat-primary {
  border: none;
  background: var(--fm-color-surface-secondary-accent-default);
  color: var(--fm-color-text-accent-default) !important;

  &:hover {
    background: var(--fm-color-surface-secondary-accent-hover);
    color: var(--fm-color-text-accent-hover) !important;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.mat-button--sm {
  padding: 0 8px !important;
  height: 28px;
  line-height: 25px !important;
}

.mat-checkbox-inner-container {
  margin-top: 0 !important;
  width: 18px !important;
  height: 18px !important;
}

.mat-checkbox-label {
  font-size: 14px;
}

.mat-menu-item {
  height: 40px !important;
  font-size: 14px;
  line-height: 40px !important;

  &:hover {
    color: $color-black;
  }
}

.mat-optgroup-label {
  height: 40px !important;
  color: rgba(0, 0, 0, 0.83);
  font-weight: 400;
  font-size: 16px;
  line-height: 40px !important;
}

.mat-snack-bar-container {
  min-width: 0 !important;
  max-width: none !important;
}

.mat-dialog-container {
  height: auto !important;

  .grid {
    overflow: hidden;
  }
}

.mat-dialog-content {
  max-height: calc(100vh - 200px) !important;
}

.mat-dialog-actions {
  flex-direction: row-reverse;
}

.mat-datepicker-toggle {
  color: inherit !important;
}

.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-chip-list .mat-chip.mat-standard-chip {
  background-color: $color-cerulean;
  padding-top: 8px;
  color: $color-white;
  font-weight: 400;

  &.mat-chip-disabled {
    opacity: 0.8;
    background-color: $color-silver;
    color: $color-black;
  }

  .fal {
    padding-top: 1px;
    color: $color-white;
    font-size: 16px;
  }
}

buyer-edit-vendors-table .actions button span.mat-button-wrapper {
  text-transform: none;

  & i {
    margin: 0 0 0 5px;
    font-size: 16px !important;
  }
}

// hide up and down arrows for input type number
input[matinput]::-webkit-outer-spin-button,
input[matinput]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[matinput][type='number'] {
  -moz-appearance: textfield;
}

// class for mat-option that wraps ngx-mat-select-search
// https://fairmarkit.atlassian.net/browse/TAIL-5997
.mat-option--sticky {
  position: sticky !important; // overwrite ngx-mat-select-search position: static
  top: 0; // fix at the top
  z-index: 1; // appear above the list
  background-color: $color-white;
}

.lazy-autocomplete.mat-autocomplete-panel {
  max-height: 240px;
}

/* items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

/* item that has been dropped. */
.cdk-drag-animating {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-expansion-panel {
  border-radius: 0 !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}
