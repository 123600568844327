.preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes mdc-circular-progress-container-rotate {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes mdc-circular-progress-spinner-layer-rotate {
    12.5% {
      transform: rotate(135deg);
    }
    25% {
      transform: rotate(270deg);
    }
    37.5% {
      transform: rotate(405deg);
    }
    50% {
      transform: rotate(540deg);
    }
    62.5% {
      transform: rotate(675deg);
    }
    75% {
      transform: rotate(810deg);
    }
    87.5% {
      transform: rotate(945deg);
    }
    100% {
      transform: rotate(1080deg);
    }
  }
  @keyframes mdc-circular-progress-color-1-fade-in-out {
    from {
      opacity: 0.99;
    }
    25% {
      opacity: 0.99;
    }
    26% {
      opacity: 0;
    }
    89% {
      opacity: 0;
    }
    90% {
      opacity: 0.99;
    }
    to {
      opacity: 0.99;
    }
  }
  @keyframes mdc-circular-progress-color-2-fade-in-out {
    from {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 0.99;
    }
    50% {
      opacity: 0.99;
    }
    51% {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes mdc-circular-progress-color-3-fade-in-out {
    from {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 0.99;
    }
    75% {
      opacity: 0.99;
    }
    76% {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes mdc-circular-progress-color-4-fade-in-out {
    from {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    75% {
      opacity: 0.99;
    }
    90% {
      opacity: 0.99;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes mdc-circular-progress-left-spin {
    from {
      transform: rotate(265deg);
    }
    50% {
      transform: rotate(130deg);
    }
    to {
      transform: rotate(265deg);
    }
  }
  @keyframes mdc-circular-progress-right-spin {
    from {
      transform: rotate(-265deg);
    }
    50% {
      transform: rotate(-130deg);
    }
    to {
      transform: rotate(-265deg);
    }
  }
  .mdc-circular-progress {
    display: inline-flex;
    position: relative;
    transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    line-height: 0;
    direction: ltr;
  }
  .mdc-circular-progress__determinate-container,
  .mdc-circular-progress__indeterminate-circle-graphic,
  .mdc-circular-progress__indeterminate-container,
  .mdc-circular-progress__spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .mdc-circular-progress__determinate-container {
    transform: rotate(-90deg);
  }
  .mdc-circular-progress__indeterminate-container {
    opacity: 0;
    font-size: 0;
    letter-spacing: 0;
    white-space: nowrap;
  }
  .mdc-circular-progress__determinate-circle-graphic,
  .mdc-circular-progress__indeterminate-circle-graphic {
    fill: rgba(0, 0, 0, 0);
  }
  .mdc-circular-progress__determinate-circle {
    transition: stroke-dashoffset 500ms 0ms cubic-bezier(0, 0, 0.2, 1);
  }
  .mdc-circular-progress__gap-patch {
    position: absolute;
    top: 0;
    left: 47.5%;
    box-sizing: border-box;
    width: 5%;
    height: 100%;
    overflow: hidden;
  }
  .mdc-circular-progress__gap-patch .mdc-circular-progress__indeterminate-circle-graphic {
    left: -900%;
    transform: rotate(180deg);
    width: 2000%;
  }
  .mdc-circular-progress__circle-clipper {
    display: inline-flex;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
  }
  .mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic {
    width: 200%;
  }
  .mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
    left: -100%;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__determinate-container {
    opacity: 0;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
    opacity: 1;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
    animation: mdc-circular-progress-container-rotate 1568.2352941176ms linear infinite;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__spinner-layer {
    animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__color-1 {
    animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
      mdc-circular-progress-color-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__color-2 {
    animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
      mdc-circular-progress-color-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__color-3 {
    animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
      mdc-circular-progress-color-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--indeterminate .mdc-circular-progress__color-4 {
    animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
      mdc-circular-progress-color-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--indeterminate
    .mdc-circular-progress__circle-left
    .mdc-circular-progress__indeterminate-circle-graphic {
    animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--indeterminate
    .mdc-circular-progress__circle-right
    .mdc-circular-progress__indeterminate-circle-graphic {
    animation: mdc-circular-progress-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
  .mdc-circular-progress--closed {
    opacity: 0;
  }
  .mat-mdc-progress-spinner {
    display: block;
    overflow: hidden;
    line-height: 0;
  }
  .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--mdc-circular-progress-active-indicator-color, transparent);
  }

  @keyframes fm-circular-progress-right-spin {
    0% {
      transform: rotate(-260deg);
    }
    50% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(-260deg);
    }
  }
  @keyframes fm-circular-progress-left-spin {
    0% {
      transform: rotate(260deg);
    }
    50% {
      transform: rotate(130deg);
    }
    100% {
      transform: rotate(260deg);
    }
  }
  .fm-spinner .mat-mdc-progress-spinner {
    border-radius: 50%;
  }
  .fm-spinner .mat-mdc-progress-spinner {
    box-shadow: inset 0 0 0 3px #eeeaf0;
  }
  .fm-spinner .mat-mdc-progress-spinner circle {
    r: 27.1;
  }
  .fm-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke-linecap: round;
  }
  .fm-spinner
    .mdc-circular-progress--indeterminate
    .mdc-circular-progress__circle-right
    .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #842cc2;
    animation-name: fm-circular-progress-right-spin;
  }
  .fm-spinner
    .mdc-circular-progress--indeterminate
    .mdc-circular-progress__circle-left
    .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #842cc2;
    animation-name: fm-circular-progress-left-spin;
  }
}
