@use '@angular/material' as mat;

@import 'legacy/colors';
@import 'colors';

@mixin table-theme($theme, $typography) {
  .mat-table {
    width: 100%;
    overflow-y: auto;

    :is(td.mat-cell, th.mat-header-cell, td.mat-footer-cell):first-child {
      border-left: 1px solid $fm-color-table-border;
    }

    :is(td.mat-cell, th.mat-header-cell, td.mat-footer-cell):last-child {
      border-right: 1px solid $fm-color-table-border;
    }

    tr.mat-header-row:first-child th.mat-header-cell {
      border-top: 1px solid $fm-color-table-border;
    }

    tr.mat-row:last-child td.mat-cell {
      border-bottom: 1px solid $fm-color-table-border;
    }

    .mat-header-row {
      height: auto;
    }

    .mat-header-cell,
    .mat-cell,
    .mat-footer-cell {
      @include mat.typography-level($typography, 'body-2');
    }

    .mat-header-row,
    .mat-row {
      border-bottom-color: $color-light-gray;
    }

    .mat-header-cell {
      padding: 12px 10px;
      color: $color-gray;
      font-weight: 500;
      font-size: 12px;
    }

    .mat-row__urgent {
      background-color: $color-vis-vis;
    }

    .mat-cell,
    .mat-footer-cell {
      vertical-align: top;
      padding: 16px 10px;
      color: $color-mine-shaft;
      font-size: 14px;

      &__no-data {
        text-align: center;
      }

      &__icon {
        width: 0; // to prevent icons column stretch
      }

      &__truncate {
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__link {
        transition: color 0.2s ease;
        cursor: pointer;
        outline: none;
        color: $fn-ic-primary;
        text-decoration: none;

        &:hover {
          color: var(--fm-color-text-link-hover);
        }
      }

      &__subtitle {
        color: $fn-ic-tertiary;
        font-size: 12px;
      }
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 20px; // override angular-material padding for first column
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-right: 20px; // override angular-material padding for first column
    }

    .mat-row:last-of-type {
      border-bottom: none;

      .mat-header-cell,
      .mat-cell,
      .mat-footer-cell {
        border-bottom: none;
      }
    }

    .mat-header-cell,
    .mat-cell.no-wrap {
      white-space: nowrap;
    }

    // TODO Move this styles to MatTableActionsColumn
    .mat-header-cell.mat-column-actions,
    .mat-cell.mat-column-actions {
      padding: 4px;
      width: 0;
      min-width: 49px;

      .mat-icon-button {
        display: flex;
        justify-content: center;
        align-items: center;

        .mat-button-wrapper {
          display: flex;

          .fal.fa-ellipsis-v {
            font-size: 20px !important;
          }
        }
      }
    }

    .mat-header-cell.mat-column-actions:last-of-type,
    .mat-cell.mat-column-actions:last-of-type {
      padding: 4px;
    }
  }

  .mat-table-sticky-border-elem-right,
  .mat-table-sticky-border-elem-left {
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
      pointer-events: none;
      content: '';
    }
  }

  .mat-table-sticky-border-elem-right {
    &:after {
      right: 100%;
      background: linear-gradient(to left, $bg-primary, rgba($bg-primary, 0));
    }
  }

  .mat-table-sticky-border-elem-left {
    &:after {
      left: 100%;
      background: linear-gradient(to right, $bg-primary, rgba($bg-primary, 0));
    }
  }

  .mat-paginator {
    border: 1px solid $color-light-gray;
    border-top: none;

    &.hide {
      display: none;
    }
  }
}
