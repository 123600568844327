@import 'colors';
@import 'mixins';

@mixin tooltip-theme($theme, $typography) {
  .mat-tooltip {
    background: rgba($tr-primary, 0.85);
    max-width: 350px;
    font-size: 12px;
    white-space: pre-line;

    &__word-break {
      @include correct-hyphenation;
    }
  }
}
