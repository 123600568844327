/** @deprecated */
$color-white: #fff;
$color-black: #000;
$color-light-black: #00000042;
$color-pampas: #f6f5f3;
$color-mercury: #e6e6e6;
$color-dark-alto: #d7d7d7;
$color-silver: #ccc;
$color-light-gray: #e7e7ec;
$color-dusty-gray: #9c9ca1;
$color-gray: #7f7f7f;
$color-gray-dark: #495057;
$color-emperor: #4f4f4f;
$color-mine-shaft: #333;
$color-cod-gray: #111;
$color-alice-blue: #f5faff; //  1/1
$color-zumthor: #ebf5ff; //  2/1
$color-foam: #fdfaff; //  1/1
$color-solitude: #e8f0fe; //  1/1
$color-hawkes-blue: #d8eafd; //  1/1
$color-sail: #bbdefb; //  1/1
$color-french-pass: #c2e0ff; //  3/1
$color-cerise: #d431a8; //  2/2
$color-wisteria: #9f63bd; //  11/6
$color-amethyst: #a95dc3; //  2/2
$color-prelude: #d5c0e6; //  2/1
$color-purple-heart: #6320e7; //  4/1
$color-cerulean: #994bd8; //  100+
$color-cerulean-dark: #994bd8;
$color-mountain-meadow: #1aaa55; //  27/18
$color-rich-green: #1ba955;
$color-moss-green: #a5d6a7; //  24/4
$color-conifer: #b4ec51; //  1/1
$color-vis-vis: #ffeba0; //  1/1
$color-goldenrod: #fadb67; //  10/7
$color-bright-sun: #fec84b; //  56/28
$color-golden-grass: #dbac1f; //  1/1
$color-orange-peel: #ff9a00; //  4/2
$color-carrot-orange: #ec9c23;
$color-punch: #db3b21;
$color-alizarin-crimzon: #ec1d1d;
$color-picton-blue: #4ba6ee;
$color-gray-lavender: #e7e7ec;
$color-gray-bunting: #303442;
$color-white-smoke: #f8f8f9;
$color-pigment-green: #1ba955;
$color-moon-yellow: #ffbe16;

$color-primary: #332994;

$fm-color-main-bg: $color-pampas;

// bid/public, there is no material
$fm-color-input-disabled-bg: $color-pampas;
$fm-color-input-add-bg: $color-pampas;
$fm-color-button-active-bg: $color-pampas;

$fm-color-table-border: $color-mercury;
$fm-color-paginator-border: $color-mercury;

//sidebar
$fm-color-sidebar-item-text: #bfc1c8;
$fm-color-sidebar-header-text: #97a1b5;
$fm-color-sidebar-active-bg: #464f5f;
$fm-color-sidebar-active-text: $color-white;
$fm-color-sidebar-bg: #273143;
$fm-color-vendor-sidebar-bg: $color-cerulean;

$fm-color-tooltip-bg: rgba(#273143, 0.93);

// invalid inputs
$fm-color-invalid: $color-alizarin-crimzon;
// error messages
$fm-color-error: $color-alizarin-crimzon;
// hint
$fm-mat-hint-color: #0000008a;
