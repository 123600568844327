@import 'colors';

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
  overscroll-behavior: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  background: var(--fm-color-background-secondary);
  font-size: 15px;
  line-height: 1.4;
}

body,
button,
input {
  font-family: var(--fm-typo-text-font-family);
}

input:disabled,
textarea:disabled {
  cursor: not-allowed;
}

@media print {
  body {
    background: $bg-primary;
  }
}

a {
  color: var(--fm-color-text-link-default);

  &:hover {
    color: var(--fm-color-text-link-hover);
  }
}
